
// Function to fetch the access token
export const getAccessToken = async () => {
    const subscriptionKey = 'ada7102b80f24a8eb0ac43a79dd3aa7d'; // Replace with your subscription key
    const region = 'northcentralus'; // Replace with your region
  
    try {
      const response = await fetch(`https://${region}.api.cognitive.microsoft.com/sts/v1.0/issueToken`, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
          'Ocp-Apim-Subscription-Key': subscriptionKey,
        },
      });
  
      if (response.ok) {
        const token = await response.text();
        return token;
      } else {
        console.error('Failed to get access token');
        return null;
      }
    } catch (error) {
      console.error('Error fetching access token:', error);
      return null;
    }
  };
  
  // Function to translate text to speech
  export const translateTextToSpeech = async (accessToken, inputText, voiceName, outputFormat) => {
    const ssml = `<speak version='1.0' xml:lang='en-US'><voice xml:lang='en-US' xml:gender='Male' name='${voiceName}'>
          ${inputText}
        </voice></speak>`;
  
    try {
      const response = await fetch('https://northcentralus.tts.speech.microsoft.com/cognitiveservices/v1', {
        method: 'POST',
        headers: {
          'Authorization': `Bearer ${accessToken}`,
          'Content-Type': 'application/ssml+xml',
          'X-Microsoft-OutputFormat': outputFormat,
          'User-Agent': 'bot-translation',
        },
        body: ssml,
      });
  
      if (response.ok) {
        // Process the audio response here
        const audioBlob = await response.blob();
        return URL.createObjectURL(audioBlob);
      } else {
        console.error('Translation request failed');
        return null;
      }
    } catch (error) {
      console.error('Error translating text to speech:', error);
      return null;
    }
  };
  