import React, { useState, useRef } from 'react';
import './App.css';
import { getAccessToken, translateTextToSpeech } from './Pronunciation';
/* import SpeechRecognitionComponent from './SpeechRecognition';
 */
function App() {
  const [inputText, setInputText] = useState('');
  const [translatedText, setTranslatedText] = useState('');
  const [translatedAudio, setTranslatedAudio] = useState(null);
  const [isListening, setIsListening] = useState(false);
  const translatedTextRef = useRef(null);
  const recognition = useRef(null);

  const fetchAndSetTranslation = async () => {
    try {
      const apiKey = '4205ff5f63ea439cac7ee4ee72a0e4e4';
      const uri = 'https://api.cognitive.microsofttranslator.com/translate?api-version=3.0&to=es';
      const headers = {
        'Content-Type': 'application/json',
        'Ocp-Apim-Subscription-Key': apiKey,
        'Ocp-Apim-Subscription-Region': 'northcentralus',
        'Accept': 'application/json',
      };

      const requestBody = [
        {
          text: inputText,
          to: 'es',
        },
      ];

      const response = await fetch(uri, {
        method: 'POST',
        headers: headers,
        body: JSON.stringify(requestBody),
      });

      if (response.ok) {
        const data = await response.json();
        const translatedText = data[0].translations[0].text;
        setTranslatedText(translatedText);

        const accessToken = await getAccessToken();
        const voiceName = 'es-MX-JorgeNeural';
        const outputFormat = 'riff-24khz-16bit-mono-pcm';

        const audioUrl = await translateTextToSpeech(accessToken, translatedText, voiceName, outputFormat);
        if (audioUrl) {
          setTranslatedAudio(audioUrl);
        }
      } else {
        console.error('Translation request failed');
      }
    } catch (error) {
      console.error('An error occurred:', error);
    }
  };

  const handleTranslate = async () => {
    setTranslatedAudio(null);
    await fetchAndSetTranslation();
  };

  const handleCopyToClipboard = () => {
    if (translatedTextRef.current) {
      navigator.clipboard.writeText(translatedTextRef.current.textContent)
        .then(() => {
          console.log('Text copied to clipboard');
        })
        .catch((err) => {
          console.error('Unable to copy text to clipboard: ', err);
        });
    }
  };

  const startSpeechRecognition = async () => {
    try {
      setIsListening(true);
  
      recognition.current = new window.webkitSpeechRecognition();
      recognition.current.lang = 'en-US';
      recognition.current.interimResults = true;
      recognition.current.continuous = true;
  
      recognition.current.onresult = (event) => {
        let finalTranscript = '';
  
        for (let i = event.resultIndex; i < event.results.length; i++) {
          const transcript = event.results[i][0].transcript;
          if (event.results[i].isFinal) {
            finalTranscript += transcript;
          }
        }
  
        setInputText(finalTranscript);
  
        // Check if there is a final transcript and stop recognition
        if (finalTranscript) {
          stopSpeechRecognition();
        }
      };
  
      recognition.current.onend = () => {
        setIsListening(false);
      };
  
      recognition.current.onerror = (event) => {
        console.error('Speech recognition error:', event.error);
        setIsListening(false);
      };
  
      recognition.current.start();
    } catch (error) {
      console.error('Error starting speech recognition:', error);
      setIsListening(false);
    }
  };
  

  const stopSpeechRecognition = () => {
    if (recognition.current) {
      recognition.current.stop();
      setIsListening(false);
    }
  };

  return (
    <div className="App">
      <main className="main">
        <h1>Translate to Spanish</h1>
        <div className="translation-container">
          <div className="input-container">
            <label>Enter text to translate or click the microphone to use your voice:</label>
            <input
              type="text"
              value={inputText}
              onChange={(e) => setInputText(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === 'Enter') {
                  handleTranslate();
                }
              }}
            />
            <div className="buttons-container">
              <button className="translate-button" onClick={handleTranslate}>
                Translate
              </button>
              <button className="mic-button" onClick={isListening ? stopSpeechRecognition : startSpeechRecognition}>
                <i className={`fa ${isListening ? 'fa-stop' : 'fa-microphone'}`} aria-hidden="true"></i>
              </button>
            </div>
          </div>
          {translatedText && !isListening && (
            <div>
              <div className="translated-text">
                <h2>Spanish Text:</h2>
                <div className="copy-container">
                  <p ref={translatedTextRef}>{translatedText}</p>
                  <button className="copy-button" onClick={handleCopyToClipboard}>
                    <i className="fa fa-clipboard" aria-hidden="true"></i>
                  </button>
                </div>
                {translatedAudio && (
                  <div className="audio-container">
                    <audio controls>
                      <source src={translatedAudio} type="audio/wav" />
                      Your browser does not support the audio element.
                    </audio>
                  </div>
                )}
              </div>
            </div>
          )}
        </div>
      </main>
    </div>
  );
}

export default App;
